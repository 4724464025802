import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
    <Layout location="not-found">
        <SEO title="NotFound" />
        <div className="container">
            <div className="wrapper-home">
                <h1>404 Not Found</h1>
                <div>Sorry the page you requested is missing. Try navigating to <Link to="/">Home</Link></div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
